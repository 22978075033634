<template>
  <div class="text-left">
    <BaseHeader :title="'Profile'"></BaseHeader>

    <BaseLoading style="min-height: 400px;height: 90vh;" v-if="$store.state.loading"></BaseLoading>

    <div v-else class="row mt-4">
      <div class="col-sm-4">
        <div
          class="card  shadow rounded"
        >
          <div class="card-body d-flex flex-row align-items-center justify-content-start text-center">
          
            <div class="  mr-2 p-sm-3 w-16 h-16  p-4  mb-sm-4  mb-2  rounded-full text-capitalize bg-slate-200 dark:bg-darkmode-400 flex items-center justify-center text-base font-medium">
              {{user.first_name.charAt(0) || ""}}{{user.last_name.charAt(0) || ""}}
            </div>

            <!-- {{user}} -->
            <div class="">
              <h6 class="font-weight-semibold text-left mb-0">{{ user_name || "" }}</h6>
              <span class="d-block opacity-75 text-left">{{ user.email || "" }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="card border-0 bg-white shadow rounded">
          <div class="card-body text-left" style>
            <!-- <h4 class="text-left">Update Profile Details</h4> -->

            <div class="settings-block-title  ">
                <div class="title">Personal Information</div>
                <p>Update your name, phone number, and emails.</p>
            </div>
            <div class="settings-container">
              <form @submit.prevent="updateProfile">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group form-group-feedback form-group-feedback-right">
                      <input
                        v-model="registerForm.first_name"
                        name="first_name"
                        type="text"
                        class="form-control"
                        placeholder="First name"
                      />
                      <div class="form-control-feedback">
                        <i class="icon-user-check text-muted"></i>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group form-group-feedback form-group-feedback-right">
                      <input
                        v-model="registerForm.last_name"
                        name="last_name"
                        type="text"
                        class="form-control"
                        placeholder="Second name"
                      />
                      <div class="form-control-feedback">
                        <i class="icon-user-check text-muted"></i>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group form-group-feedback form-group-feedback-right">
                  <input
                    v-model="registerForm.email"
                    name="email"
                    type="email"
                    class="form-control"
                    placeholder="Email Address"
                  />
                  <div class="form-control-feedback">
                    <i class="icon-mention text-muted"></i>
                  </div>
                </div>

                <div class="row">
                  <!-- <div class="col-sm-4">
                    <div class="form-group form-group-feedback form-group-feedback-right">
                      <vue-select 
                          label="name" 
                          v-model="registerForm.country" 
                          placeholder="Country: Start typing to search" 
                          :options="countryNames" 
                          :reduce="country => country.name">
                        </vue-select>
                    </div>
                  </div> -->
                  <div class="col-sm-8">
                    <div class="form-group form-group-feedback form-group-feedback-right">
                      <input
                        v-model="registerForm.phone"
                        name="phone"
                        type="tel"
                        class="form-control"
                        placeholder="Your Phone"
                      />
                      <div class="form-control-feedback">
                        <i class="icon-phone text-muted"></i>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  class="btn btn-success rounded-0 text-light"
                  :disabled="registerForm.busy"
                >Update Details</button>
              </form>
            </div>

            <div class="settings-block-title  ">
                <div class="title">Change Password</div>
                <p>Change your password: make it totally new or stronger.</p>
            </div>
            <div class="settings-container">
            <form @submit.prevent="changePassword">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group form-group-feedback form-group-feedback-right">
                    <input
                      v-model="registerForm.password"
                      name="password"
                      type="password"
                      class="form-control"
                      placeholder="New password"
                      required
                    />
                    <div class="form-control-feedback">
                      <i class="icon-user-lock text-muted"></i>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group form-group-feedback form-group-feedback-right">
                    <input
                      v-model="registerForm.password_confirmation"
                      name="password_confirmation"
                      type="password"
                      class="form-control"
                      placeholder="Password confirmation"
                      required
                    />
                    <div class="form-control-feedback">
                      <i class="icon-user-lock text-muted"></i>
                    </div>
                  </div>
                </div>
              </div>

              <button
                type="submit"
                :disabled="registerForm.busy"
                class="btn btn-success rounded-0 text-light"
              >Change Password</button>
            </form>
            </div>
          </div>
        </div>

        
      </div>
    </div>

    <BaseOverlay v-if="is_loading"></BaseOverlay>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {countries} from "countries-list";

export default {
  name: "Profile",
  computed: {
    countryNames() {
      return Object.keys(countries).map((country) => countries[country].name)
    },

    ...mapState("auth", ["user"]),
    ...mapState("setups", ["countries"]),
    user_name() {
      let first = this.user.first_name || "";
      let last = this.user.last_name || "";
      return first + " " + last;
    },
  },
  data() {
    return {
      is_loading: false,
      registerForm: new this.$Form({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        country: "",
        password: "",
        password_confirmation: "",
      }),
    };
  },
  mounted() {
    this.$store.state.loading = true
    this.doGetAuthUser()
      .then(() => {
        this.registerForm.first_name = this.user.first_name;
        this.registerForm.last_name = this.user.last_name;
        this.registerForm.email = this.user.email;
        this.registerForm.phone = this.user.phone;
        this.registerForm.country = this.user.country;
      })
      .catch((err) => console.log(err))
      .finally(() => this.$store.state.loading = false)
    this.registerForm.busy = true;
    this.getCountries()
      .then(() => {
        this.registerForm.busy = false;
      })
      .catch((err) => console.log(err))
      .finally(() => (this.registerForm.busy = false));
  },
  methods: {
    
    encodePassword(password) {
      return window.btoa(unescape(encodeURIComponent(password)))
    },

    ...mapActions("auth", [
      "doUpdateProfile",
      "doChangePassword",
      "doGetAuthUser",
    ]),
    ...mapActions("setups", ["getCountries"]),

    changePassword() {
      if (
        this.registerForm.password ===
          this.registerForm.password_confirmation &&
        this.registerForm.password
      ) {
        this.is_loading = true;

        this.registerForm.password = this.encodePassword(this.registerForm.password)
        this.registerForm.password_confirmation = this.encodePassword(this.registerForm.password_confirmation)
       
        console.log("password form", this.registerForm);
        
        this.doChangePassword(this.registerForm)
          .then((res) => {
            console.log("change passres", res);
            this.$notify({
              title: "Success",
              text: "Password updated successifully!",
            });
            this.registerForm.password = ""
            this.registerForm.password_confirmation = ""
          })
          .catch((err) => {
            this.$notify({
              title: "Error",
              text: err.response.data ? err.response.data.Message : "Error",
              style: "danger",
            });
          })
          .finally(() => (this.is_loading = false));
      } else
        this.$notify({
          title: "Error",
          text: "Passwords do not match",
          style: "danger",
        });
    },
    updateProfile() {
      this.is_loading = true;
      delete this.registerForm.password
      delete this.registerForm.password_confirmation
      this.doUpdateProfile(this.registerForm)
        .then(() => {
          this.$notify({
            title: "Success",
            text: "Profile updated successifully!",
          });
        })
        .catch((err) => {
          this.$notify({
            title: "Error",
            text: err.response.data ? err.response.data.Message : "Error",
            style: "danger",
          });
        })
        .finally(() => (this.is_loading = false));
    },
  },
  
};
</script>
